import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import kaarjalad from "../../images/scissorleg.png"

const kaarjaladSisu = () => (
  <Layout>
    <SEO title="Käärjalgadega platvormid" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>KÄÄRJALGADEGA PLATVORMID</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/platvormid/platvormid/">
          <div className="epo antistaatilised">
            <h2>Platvormid</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link
          to="/platvormid/kaarjalgadega/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo epohoordemass">
            <h2>Käärjalgadega platvormid</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/platvormid/motoriseeritud/">
          <div className="epo isetasanduv-epomasskate">
            <h2>Mootoriga platvormid</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link to="/platvormid/tellingplatvormid/">
          <div className="epo treppidele">
            <h2>Telling-platvormid</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/platvormid/raam/">
          <div className="epo dekoratiivporandad">
            <h2>Lavade raamid</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
      </div>

      <div className="antistaatilised-wrapper">
        <div className="antistaatilised-text">
          <h3>Käärjalgadega platvormid</h3>
          <p>
            Enimmüüdud käärjalgadega platvorm. Universaalseks kasutamiseks
            mõeldud lavaplatvorm, mis osutub ideaalseks lahenduseks lavakõrguse
            kiireks muutmiseks ning kalde ja kalde tasapindade seadmiseks.
          </p>
        </div>
        <div className="subpage-img platvorm-img">
          <img src={kaarjalad} alt="Käärjalgadega platvorm"></img>
        </div>
      </div>
    </div>
  </Layout>
)

export default kaarjaladSisu
